<template>
	<div class="main-content">
		<div>
			<ktv-breadcrumb
				:folder="$t('Premium Points', 'Premium Points')"
				:page="$t('Disburse Request', 'Disburse Request')"
				:title="$t('Premium Points Disburse Request Detail', 'Premium Points Disburse Request Detail')"
			/>
			<b-row>
				<b-col md="12 mb-30">
					<b-row class="mb-4">
						<b-col>
							<router-link v-b-tooltip.hover :to="{ name: 'Premium Points Disburse Request List' }">
								<ktv-back :label="$t('Back to disburse request list', 'Back to disburse request list')" />
							</router-link>
						</b-col>
					</b-row>
					<div>
						<b-row>
							<b-col>
								<h2>{{ $t("Premium Points Disburse Request Detail", "Premium Points Disburse Request Detail") }} ({{ $route.params.id }})</h2>
							</b-col>
						</b-row>
						<b-row class="px-16">
							<div class="text-14">
								<span class="text-gray-400">{{ $t("Unit", "Unit") }}: </span>
								<span class="font-weight-600 text-gray-500">{{ disburseDetail && disburseDetail.unit ? disburseDetail.unit : "-" }}</span>
							</div>
							<div class="text-14 ml-4">
								<span class="text-gray-400">{{ $t("Hub Area", "Hub Area") }}: </span>
								<span class="font-weight-600 text-gray-500">{{ disburseDetail && disburseDetail.hubArea ? disburseDetail.hubArea : "-" }}</span>
							</div>
							<div class="text-14 ml-4">
								<span class="text-gray-400">{{ $t("Premium Period", "Premium Period") }}: </span>
								<span class="font-weight-600 text-gray-500">
									{{ disburseDetail && disburseDetail.period ? disburseDetail.period : "-" }}
								</span>
							</div>
						</b-row>
						<b-row class="mt-16">
							<b-col class="col-md-auto text-14">
								<div class="text-gray-400">{{ $t("Farmer", "Farmer") }}</div>
								<div class="font-weight-600 text-gray-500">{{ disburseDetailTotalRows | Number }}</div>
							</b-col>
							<b-col class="col-md-auto text-14">
								<div class="text-gray-400">{{ $t("Netto (kg)", "Netto (kg)") }}</div>
								<div class="font-weight-600 text-gray-500">
									{{ disburseDetail && disburseDetail.netto ? disburseDetail.netto : "-" | Number }}
								</div>
							</b-col>
							<b-col class="col-md-auto text-14">
								<div class="text-gray-400">{{ $t("Premium Points", "Premium Points") }}</div>
								<div class="font-weight-600 text-gray-500">
									{{ disburseDetail && disburseDetail.totalPremium ? Math.round(disburseDetail.totalPremium) : "-" | Number }}
								</div>
							</b-col>
							<b-col v-if="disburseDetail && disburseDetail.totalFee" class="col-md-auto text-14">
								<div class="text-gray-400">
									<span class="mr-1">{{ $t("Total Fee", "Total Fee") }}</span>
									<img id="total-fee" :src="icons.info" />
									<b-tooltip target="total-fee">
										{{
											`${$t("Fee", "Fee")} ${idrFilter(Math.round(disburseDetail.totalFee / disburseDetailTotalRows))}/${$t(
												"Farmer",
												"Farmer",
											).toLowerCase()}`
										}}
									</b-tooltip>
								</div>
								<div class="font-weight-600 text-gray-500">
									{{ disburseDetail && disburseDetail.totalFee ? Math.round(disburseDetail.totalFee) : "-" | Rupiah }}
								</div>
							</b-col>
							<b-col v-if="disburseDetail && disburseDetail.totalFee" class="col-md-auto text-14">
								<div class="font-weight-600 text-gray-500">{{ $t("Total Payment", "Total Payment") }}</div>
								<div class="font-weight-600 text-gray-500">
									{{ disburseDetail && disburseDetail.totalFee ? Math.round(disburseDetail.totalFee) : "-" | Rupiah }}
								</div>
							</b-col>
							<b-col />
							<b-col class="col-md-auto text-14 text-right">
								<div class="text-gray-400">{{ $t("Date Submitted", "Date Submitted") }}</div>
								<div class="font-weight-600 text-gray-500">
									{{ disburseDetail && disburseDetail.dateSubmitted ? getDate(disburseDetail.dateSubmitted) : "-" }}
								</div>
							</b-col>
							<b-col class="col-md-auto text-14 text-right">
								<div class="text-gray-400">{{ $t("Submitted By", "Submitted By") }}</div>
								<div class="font-weight-600 text-gray-500">
									{{ disburseDetail && disburseDetail.submitedBy ? disburseDetail.submitedBy : "-" }}
								</div>
							</b-col>
						</b-row>
						<div class="mt-32">
							<ktv-table
								:columns="columns"
								:filter="false"
								:is-loading="is.loading"
								:line-numbers="false"
								:rows="disburseDetailData"
								:search-enabled="false"
								:search-placeholder="$t('Search by farmer name', 'Search by farmer name')"
								:total-rows="disburseDetailTotalRows"
								min-height="250px"
								mode="remote"
								@on-page-change="onPageChange"
								@on-per-page-change="onPerPageChange"
								@on-search="onSearch"
								@on-sort-change="onSortChange"
							>
								<template #actionleft>
									<div class="d-flex">
										<ktv-button
											:text="$t('Report Disburse Request', 'Report Disburse Request')"
											color="light"
											icon="download"
											@click="exportReport"
										/>
									</div>
								</template>
								<template #columns="{ props }">
									{{ $t(props.column.label, props.column.label) }}
								</template>
								<template #rows="{ props }">
									<span v-if="props.column.field === 'number'">{{ props.row.originalIndex + (params.page - 1) * params.limit + 1 }}</span>
									<span v-else-if="props.column.field === 'ptPremiumDisburseDetailId'">
										{{ props.row.ptPremiumDisburseDetailId ? props.row.ptPremiumDisburseDetailId : "-" }}
									</span>
									<span v-else-if="props.column.field === 'farmerName'">{{ props.row.farmerName ? props.row.farmerName : "-" }}</span>
									<span v-else-if="props.column.field === 'useFarmcloud'">
										{{ props.row.useFarmcloud ? (props.row.useFarmcloud === "1" ? "FarmCloud" : "Non FarmCloud") : "Not available" }}
									</span>
									<span v-else-if="props.column.field === 'netto'">
										{{ props.row.netto >= 0 ? idrFilter(props.row.netto) : "-" }}
									</span>
									<span v-else-if="props.column.field === 'premiumAmount'">
										{{ props.row.premiumPoint >= 0 ? IDRFilter(Math.round(props.row.premiumPoint)) : "-" }}
									</span>
									<span v-else-if="props.column.field === 'premiumPoint'">
										{{ props.row.premiumPoint >= 0 ? idrFilter(Math.round(props.row.premiumPoint)) : "-" }}
									</span>
									<span v-else-if="props.column.field === 'statusDescription'">
										{{ props.row.statusDescription ? props.row.statusDescription : "-" }}
									</span>
									<span v-else-if="props.column.field === 'statusDeliveryDescription'">
										{{ props.row.statusDeliveryDescription ? props.row.statusDeliveryDescription : "-" }}
									</span>
								</template>
							</ktv-table>
						</div>
						<div v-if="disburseDetail && disburseDetail.statusDisburseType === 220" class="mt-32" align="center">
							<ktv-button
								:loading="is.loading"
								:text="$t('Disburse Premium Points', 'Disburse Premium Points')"
								color="primary"
								icon="credit-card"
								@click="disburse()"
							/>
						</div>
					</div>
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
	import { reduce } from "underscore"
	import { KtvBack, KtvButton, KtvTable } from "@/components"
	import { mapActions, mapGetters, mapState } from "vuex"

	import infoIcon from "@/assets/images/icon/info-gray.svg"

	const icons = {
		info: infoIcon,
	}

	export default {
		name: "PremiumPointsDisburseRequestDetail",
		metaInfo: {
			title: "View Premium Points Disburse Request",
		},
		components: { KtvBack, KtvButton, KtvTable },
		data() {
			return {
				columns: [
					{
						field: "number",
						html: true,
						label: "Number",
						sortable: false,
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "ptPremiumDisburseDetailId",
						label: "ID",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "farmerName",
						label: "Farmer Name",
						thClass: "text-left",
						width: "150px",
					},
					{
						field: "useFarmcloud",
						label: "Farmer Type",
						sortable: false,
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "netto",
						label: "Netto (kg)",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "premiumAmount",
						label: "Premium",
						sortable: false,
						tdClass: "text-right",
						thClass: "text-right",
						width: "150px",
					},
					{
						field: "premiumPoint",
						label: "Premium Points",
						tdClass: "text-right",
						thClass: "text-right",
						width: "150px",
					},
					{
						field: "statusDescription",
						label: "Status",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
					{
						field: "statusDeliveryDescription",
						label: "Status Delivery",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
				],
				icons: icons,
				is: {
					loading: false,
				},
				params: {
					disburseId: parseInt(String(this.$route.params.id).replaceAll("PRE", ""), 10) || null,
					limit: 10,
					order: null,
					page: 1,
					search: null,
				},
			}
		},
		computed: {
			...mapGetters({
				disburseDetailData: "PREMIUMPOINT/disburseDetailData",
				disburseDetailTotalRows: "PREMIUMPOINT/disburseDetailTotalRows",
			}),
			...mapState("PREMIUMPOINT", ["disburseDetail"]),
			sumNetto() {
				const totalNetto = reduce(
					this.disburseDetailData,
					(total, data) => {
						return parseFloat(data.netto, 10) + total
					},
					0,
				)

				return totalNetto
			},
			sumPremiumPoints() {
				const totalPremiumPoints = reduce(
					this.disburseDetailData,
					(total, data) => {
						return parseFloat(data.premiumPoint, 10) + total
					},
					0,
				)

				return totalPremiumPoints
			},
		},
		watch: {
			"$route.params.id": {
				handler(newValue, oldValue) {
					if (newValue) {
						if (newValue !== oldValue) {
							this.getData()
						}
					} else {
						this.$swal(
							`${this.$t("Failed", "Failed")}!`,
							this.$t("Please make sure input the right disburse id", "Please make sure input the right disburse id"),
							"error",
						)

						this.$router.push({
							name: "Premium Points Disburse Request List",
						})
					}
				},
				immediate: true,
			},
		},
		beforeDestroy() {
			this.resetDisburseDetail()
		},
		methods: {
			...mapActions({
				getDisburseDetail: "PREMIUMPOINT/getDisburseDetail",
				getReport: "PREMIUMPOINT/getReport",
				resetDisburseDetail: "PREMIUMPOINT/resetDisburseDetail",
				sendNotifToFC: "PREMIUMPOINT/sendNotifToFC",
			}),
			disburse() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.sendNotifToFC({ amount: this.idrFilter(this.sumPremiumPoints), disburseId: parseInt(this.params.disburseId, 10) })
					.then(() => {
						this.getData()
					})
					.catch(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
			},
			downloadFile(url, fileName) {
				fetch(url)
					.then((response) => response.blob())
					.then((blob) => {
						const link = document.createElement("a")
						link.href = URL.createObjectURL(blob)
						link.download = fileName
						link.click()
					})
					.catch((error) => {
						const err = error.toJSON()
						const errorMessage = err.code ? `${err.code}: ${err.message}` : err.message

						this.$swal(err.name, errorMessage, "error")
					})
			},
			exportReport() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getReport({ disburseId: this.params.disburseId, type: "disburseRequest" })
					.then((transactionReport) => {
						transactionReport.forEach(({ fileName, url }) => {
							const fileExtension = fileName.split(".").pop()

							const replacedFileName = `${this.$t("Report Disburse Request", "Report Disburse Request")} (${
								this.$route.params.id
							}).${fileExtension}`
							this.downloadFile(url, replacedFileName)
						})

						this.is = {
							...this.is,
							loading: false,
						}
					})
					.catch(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
			},
			getData() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getDisburseDetail(this.params).then(() => {
					this.is = {
						...this.is,
						loading: false,
					}
				})
			},
			getDate(timestamp) {
				const date = new Date(timestamp)

				return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`
			},
			idrFilter(amount) {
				return this.$options.filters.Number(amount)
			},
			IDRFilter(amount) {
				return this.$options.filters.Rupiah(amount)
			},
			onPageChange({ currentPage }) {
				this.updateParams({ page: currentPage })

				this.getData()
			},
			onPerPageChange({ currentPerPage }) {
				this.updateParams({ limit: currentPerPage, page: 1 })

				this.getData()
			},
			onSearch($event) {
				this.updateParams({ search: $event ? `farmerName=${$event}` : null })

				this.getData()
			},
			onSortChange($event) {
				const { field, type } = $event[0]
				let orderQuery = null

				if (type !== "none") {
					orderQuery = "namaField=" + field + "&orderBy=" + type.toUpperCase()
				} else {
					orderQuery = null
				}

				this.updateParams({ order: orderQuery })

				this.getData()
			},
			updateParams(params) {
				this.params = { ...this.params, ...params }
			},
		},
	}
</script>
